<template>
  <a-form-model ref="ruleForm" :model="rincian_peserta_didik" :rules="rules">
    <header-menu title="Data Periodik" />
    <a-form-model-item ref="tinggi_badan" :colon="false" prop="tinggi_badan">
      <span slot="label">
        Tinggi Badan&nbsp;
        <a-tooltip title="Tinggi badan peserta didik dalam satuan sentimeter.">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input
        size="large"
        addon-after="cm"
        v-model.number="rincian_peserta_didik.tinggi_badan"
        @blur="
          () => {
            $refs.tinggi_badan.onFieldBlur();
          }
        "
      >
      </a-input>
    </a-form-model-item>
    <a-form-model-item ref="berat_badan" :colon="false" prop="berat_badan">
      <span slot="label">
        Berat Badan&nbsp;
        <a-tooltip title="Berat badan peserta didik dalam satuan kilogram.">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input
        size="large"
        addon-after="kg"
        v-model.number="rincian_peserta_didik.berat_badan"
        @blur="
          () => {
            $refs.berat_badan.onFieldBlur();
          }
        "
      >
      </a-input>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="jarak_tempat_tinggal_ke_sekolah">
      <span slot="label">
        Jarak Tempat Tinggal ke Sekolah&nbsp;
        <a-tooltip title="Jarak rumah peserta didik ke sekolah">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-modal
        v-model="visibleModalLocation"
        width="90%"
        title="Pilih Lokasi Rumah Peserta Didik"
        on-ok="handleOk"
        :destroyOnClose="true"
      >
        <template slot="footer">
          <a-button key="back" @click="reset"> Reset</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loadingModalLocation"
            @click="handleOkModalLocation"
          >
            Pilih
          </a-button>
        </template>
        <div class="d-flex flex-column flex-lg-row">
          <div class="w-100 w-lg-75" style="height: 400px">
            <location-picker :key="locationKey" @change="changeLocation" />
          </div>
          <div class="w-100 w-lg-25 mt-3 mt-lg-0 ml-0 ml-lg-3">
            <h3 class="text-uppercase">Petunjuk Pemilihan Lokasi:</h3>
            <ul>
              <li>
                <h5>Geser daerah peta untuk memindahkan lokasi</h5>
              </li>
              <li>
                <h5>
                  <span class="font-weight-bold">Klik 2x</span> untuk memilih
                  lokasi terpilih
                </h5>
              </li>
              <li><h5>Geser pin untuk memindahkan lokasi terpilih</h5></li>
            </ul>
            <hr />
            <a-row :gutter="16">
              <a-col :xs="24">
                <a-form-item label="Alamat">
                  <a-textarea
                    :disabled="true"
                    v-model="currentLocation.address"
                    :auto-size="{ minRows: 4, maxRows: 5 }"
                  />
                </a-form-item>
              </a-col>
              <a-col :xs="24" :md="12">
                <a-form-item label="Jarak Tempuh">
                  <a-input
                    v-model="currentLocation.distanceInKm"
                    size="large"
                    :disabled="true"
                    addon-after="KM"
                  />
                </a-form-item>
              </a-col>
              <a-col :xs="24" :md="12">
                <a-form-item label="Waktu Tempuh">
                  <a-input
                    v-model="currentLocation.durationDisplay"
                    size="large"
                    :disabled="true"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-modal>
      <div class="d-flex">
        <a-input
          :disabled="true"
          v-model="rincian_peserta_didik.jarak_tempat_tinggal_ke_sekolah"
          size="large"
          addon-after="KM"
        />
        <a-button
          @click="handleVisibleModalLocation"
          type="default"
          size="large"
          class="btn btn-outline-primary rounded ml-3"
          >Pilih Lokasi</a-button
        >
      </div>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="waktu_tempuh_ke_sekolah">
      <span slot="label">
        Waktu Tempuh ke Sekolah&nbsp;
        <a-tooltip
          title="
    Waktu tempuh peserta didik ke sekolah."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input
        :disabled="true"
        v-model="rincian_peserta_didik.waktu_tempuh_ke_sekolah_display"
        size="large"
      />
    </a-form-model-item>
    <a-form-model-item
      ref="jumlah_saudara_kandung"
      :colon="false"
      prop="jumlah_saudara_kandung"
    >
      <span slot="label">
        Jumlah Saudara Kandung&nbsp;
        <a-tooltip
          title="Jumlah saudara kandung yang dimiliki peserta didik. Jumlah saudara kandung dihitung tanpa menyertakan peserta didik, dengan
rumus jumlah kakak ditambah jumlah adik. Isikan 0 apabila anak tunggal."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input-number
        size="large"
        :max="100"
        :min="0"
        v-model="rincian_peserta_didik.jumlah_saudara_kandung"
      ></a-input-number>
    </a-form-model-item>
    <header-menu title="Prestasi" />
    <table-prestasi
      :dataTable="rincian_peserta_didik.data_prestasi"
      @added="addPrestasi"
    />
    <header-menu title="Beasiswa" />
    <table-beasiswa
      :dataTable="rincian_peserta_didik.data_beasiswa"
      @added="addBeasiswa"
    />
  </a-form-model>
</template>
<script>
import { getDistanceFromLatLonInKm } from '@/helpers/distanceMatrix'
import getDuration from '@/helpers/duration'
const HeaderMenu = () => import('@/components/app/Registration/Header')
const LocationPicker = () => import('@/components/app/shared/LocationPicker')
const TablePrestasi = () => import('@/components/app/Registration/FormRincianPesertaDidik/TablePrestasi')
const TableBeasiswa = () => import('@/components/app/Registration/FormRincianPesertaDidik/TableBeasiswa')
const rules = {
  tinggi_badan: [
    {
      required: true,
      validator: (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Tinggi Badan wajib diisi!'))
        }
        if (!Number.isInteger(value)) {
          callback(new Error('Tinggi Badan harus angka!'))
        } else {
          if (value < 1) {
            callback(new Error('Tinggi Badan wajib harus lebih dari 0!'))
          } else {
            callback()
          }
        }
      },
      trigger: 'blur',
    },
  ],
  berat_badan: [
    {
      required: true,
      validator: (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Berat Badan wajib diisi!'))
        }
        if (!Number.isInteger(value)) {
          callback(new Error('Berat Badan harus angka!'))
        } else {
          if (value < 1) {
            callback(new Error('Berat Badan wajib harus lebih dari 0!'))
          } else {
            callback()
          }
        }
      },
      trigger: 'blur',
    },
  ],
  jumlah_saudara_kandung: [
    {
      required: true,
      validator: (rule, value, callback) => {
        if (value === null || value === undefined) {
          return callback(new Error('Jumlah Saudara Kandung wajib diisi!'))
        }
        if (!Number.isInteger(value)) {
          callback(new Error('Jumlah Saudara Kandung harus angka!'))
        } else {
          if (value < 0) {
            callback(new Error('Jumlah Saudara Kandung wajib harus lebih dari sama dengan 0!'))
          } else {
            callback()
          }
        }
      },
      trigger: 'blur',
    },
  ],
  jarak_tempat_tinggal_ke_sekolah: [
    { required: true, message: 'Jarak Tempat Tinggal ke Sekolah wajib diisi!', trigger: 'change' },
  ],
  waktu_tempuh_ke_sekolah: [
    { required: true, message: 'Waktu Tempuh ke Sekolah wajib diisi!', trigger: 'change' },
  ],
}
export default {
  components: {
    HeaderMenu,
    LocationPicker,
    TablePrestasi,
    TableBeasiswa,
  },
  data() {
    return {
      rincian_peserta_didik: {
        tinggi_badan: '',
        berat_badan: '',
        jarak_tempat_tinggal_ke_sekolah: '',
        waktu_tempuh_ke_sekolah: '',
        waktu_tempuh_ke_sekolah_display: '',
        jumlah_saudara_kandung: '',
        location: {},
        data_prestasi: [],
        data_beasiswa: [],
      },
      locationKey: 1,
      visibleModalLocation: false,
      loadingModalLocation: false,
      rules,
      currentLocation: {
        distanceInKm: '',
        duration: '',
        durationDisplay: '',
        position: {
          lat: '',
          lng: '',
        },
        address: '',
      },
    }
  },
  mounted() {
    this.rincian_peserta_didik = {
      ...this.rincian_peserta_didik,
      ...this.data,
    }
  },
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
  },
  methods: {
    reset() {
      this.locationKey += 1
      this.currentLocation = {}
    },
    changeLocation(payload) {
      console.log('payload', payload)
      const { alamat } = this.institusi
      // console.log(alamat)
      // const parsedAlamat = JSON.parse(alamat)
      const lat2 = alamat ? alamat.latitude : -6.227373990352365
      const lng2 = alamat ? alamat.longitude : 106.83368453847707
      const avgSpeed = 18
      payload.distanceInKm = Number(getDistanceFromLatLonInKm(payload.position.lat, payload.position.lng, lat2, lng2).toFixed(1))
      payload.duration = payload.distanceInKm / avgSpeed * 3600
      payload.durationDisplay = getDuration(payload.distanceInKm / avgSpeed * 3600)
      this.currentLocation = payload
    },
    handleOkModalLocation() {
      this.handleVisibleModalLocation()
      this.rincian_peserta_didik.location = this.currentLocation
      this.rincian_peserta_didik.jarak_tempat_tinggal_ke_sekolah = this.currentLocation.distanceInKm
      this.rincian_peserta_didik.waktu_tempuh_ke_sekolah = this.currentLocation.duration
      this.rincian_peserta_didik.waktu_tempuh_ke_sekolah_display = this.currentLocation.durationDisplay
    },
    handleVisibleModalLocation() {
      this.visibleModalLocation = !this.visibleModalLocation
    },
    addPrestasi(value) {
      value.key = this.rincian_peserta_didik.data_prestasi.length + 1
      this.rincian_peserta_didik.data_prestasi.push(value)
    },
    addBeasiswa(value) {
      value.key = this.rincian_peserta_didik.data_beasiswa.length + 1
      this.rincian_peserta_didik.data_beasiswa.push(value)
    },
  },
  props: ['isClickAction', 'data'],
  watch: {
    'isClickAction.next'(newVal) {
      if (newVal) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit('actionValidate', { action: 'next', data: { rincian_peserta_didik: this.rincian_peserta_didik } })
          } else {
            this.$emit('actionValidate', false)
            this.$notification.error({
              message: 'Maaf',
              description: 'Ada data wajib yang belum terisi.',
            })
            return false
          }
        })
      }
    },
    'isClickAction.previous'(newVal) {
      if (newVal) {
        this.$refs.ruleForm.validate(valid => {
          // if (valid) {
          this.$emit('actionValidate', { action: 'previous', data: { rincian_peserta_didik: this.rincian_peserta_didik } })
          // } else {
          //   this.$emit('actionValidate', false)
          //   return false
          // }
        })
      }
    },
  },
}
</script>
